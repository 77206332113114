import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DashboardIcon from "@material-ui/icons/Dashboard"
import ListAlt from "@material-ui/icons/ListAlt"
import MicIcon from "@material-ui/icons/MicRounded"
import QueueMusic from "@material-ui/icons/QueueMusic"
import Videocam from "@material-ui/icons/Videocam"
import { useRouter } from "next/dist/client/router"
import React from "react"

export const MainNavigation: React.FunctionComponent = () => {
  const router = useRouter()
  return (
    <div>
      <ListItem
        button
        selected={router.pathname === "/dashboard"}
        onClick={() => {
          router.push("/dashboard")
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary='Dashboard' />
      </ListItem>

      <ListItem
        button
        selected={router.pathname === "/content-videos"}
        onClick={() => {
          router.push("/content-videos")
        }}
      >
        <ListItemIcon>
          <Videocam />
        </ListItemIcon>
        <ListItemText primary='Content Videos' />
      </ListItem>
      <ListItem
        button
        selected={router.pathname === "/flowhalls/playlists"}
        onClick={() => {
          router.push("/flowhalls/playlists")
        }}
      >
        <ListItemIcon>
          <QueueMusic />
        </ListItemIcon>
        <ListItemText primary='Playlists' />
      </ListItem>
      <ListItem
        button
        selected={router.pathname === "/distractions"}
        onClick={() => {
          router.push("/distractions")
        }}
      >
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        <ListItemText primary='Distraction List' />
      </ListItem>

      <ListItem
        button
        selected={router.pathname === "/categories"}
        onClick={() => {
          router.push("/categories")
        }}
      >
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        <ListItemText primary='Group Categories' />
      </ListItem>

      <ListItem
        button
        selected={router.pathname === "/coaches"}
        onClick={() => {
          router.push("/coaches")
        }}
      >
        <ListItemIcon>
          <MicIcon />
        </ListItemIcon>
        <ListItemText primary='Coach Messages' />
      </ListItem>

      <ListItem
        button
        selected={router.pathname === "/coach-names"}
        onClick={() => {
          router.push("/coach-names")
        }}
      >
        <ListItemIcon>
          <MicIcon />
        </ListItemIcon>
        <ListItemText primary='Coach Names' />
      </ListItem>

      <ListItem
        button
        selected={router.pathname === "/review"}
        onClick={() => {
          router.push("/review")
        }}
      >
        <ListItemIcon>
          <MicIcon />
        </ListItemIcon>
        <ListItemText primary='Review Coach Lines' />
      </ListItem>

      <ListItem
        button
        selected={router.pathname === "/coupon-codes"}
        onClick={() => {
          router.push("/coupon-codes")
        }}
      >
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        <ListItemText primary='Coupon Codes' />
      </ListItem>
    </div>
  )
}
