import { auth, firebase, useCurrentUser } from "@centered/firebase"
import AccentButton from "@centered/components/dist/AccentButton"

export default function LoginApp(): JSX.Element {
  const { currentUser } = useCurrentUser()

  return (
    <div className='w-full flex items-center justify-center h-80'>
      <AccentButton
        onClick={async () => {
          const provider = new firebase.auth.GoogleAuthProvider()
          provider.addScope("https://www.googleapis.com/auth/userinfo.email")
          provider.addScope("https://www.googleapis.com/auth/userinfo.profile")

          await auth.signInWithPopup(provider)
        }}
      >
        Unlock the Mysteries of the Universe
      </AccentButton>

      <pre>{JSON.stringify(currentUser, null, 2)}</pre>
    </div>
  )
}
